import auth from "@/middleware/auth";

export default [
  {
    path: "/stocktake/:stocktake/fixture/:fixture",
    name: "fixture",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "fixture" */ "@/modules/fuap/fixture/views/Fixture"
      ),
  },
];
