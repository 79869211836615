<template>
  <div class="flex justify-between items-center" @click="toggleTheme">
    <div
      class="w-16 h-8 flex items-center bg-gray-200 rounded-full p-1 duration-300 dark:bg-gray-700 ease-in-out relative"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 absolute right-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 absolute"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
      <div
        class="bg-gray-500 w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out z-50"
        :class="{ 'translate-x-8': switchState }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["darkMode"],
  computed: {
    switchState: function () {
      if (this.darkMode === "dark") {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch("ui/toggleTheme");
    },
  },
};
</script>
