import FuapService from "@/modules/fuap/Service";
import router from "@/router";

export const namespaced = true;

export const state = {
    isProcessing: false,
    hasError: false,
    error: null,
};

export const mutations = {
    SET_PROCESSING(state, isProcessing) {
        state.isProcessing = isProcessing;
    },
    SET_HAS_ERROR(state, hasError) {
        state.hasError = hasError;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    async createStocktake({ commit }, [store, payload]) {
        commit("SET_HAS_ERROR", false);
        commit("SET_ERROR", null);
        
        try {
            commit("SET_PROCESSING", true)
            await FuapService.createStocktake(payload, store).then(() => {
                commit("SET_PROCESSING", false);
                router.push({ name: "dashboard" });
            });
        } catch (e) {
            commit("SET_PROCESSING", false);
            commit("SET_HAS_ERROR", true);

           if(e.response.status === 403 || e.response.status === 422) {
               commit("SET_ERROR", e.response.data.message);
           }

           if(e.response.status === 404) {
               commit("SET_ERROR", "Store Not Found.");
           }
        }
    },
};

export const getters = {
    processing: (state) => {
        return state.isProcessing;
    },
    hasError: (state) => {
        return state.hasError;
    },
    error: (state) => {
        return state.error;
    },
};