import axios from "axios";
import store from "@/store";
import router from "@/router";

export const authClient = axios.create({
  baseURL: "https://" + process.env.VUE_APP_URL,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    if (response.data.two_factor === true) {
      router.push("/two-factor-challenge");
    }
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/authUser"] &&
      !store.getters["auth/guest"]
    ) {
      store.dispatch("auth/logout");
    }
    if (
      error.response &&
      [423].includes(error.response.status) &&
      store.getters["auth/authUser"] &&
      !store.getters["auth/guest"]
    ) {
      store.dispatch("auth/setPasswordRequired", true);
    }
    return Promise.reject(error);
  }
);

export default {
  async login(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/login", payload);
  },
  async twoFactorChallenge(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/two-factor-challenge", payload);
  },
  async enableTwoFactor() {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.post("user/two-factor-authentication");
  },
  async disableTwoFactor() {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.delete("user/two-factor-authentication");
  },
  async getTwoFactorQR() {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.get("user/two-factor-qr-code");
  },
  async getTwoFactorRecoveryCodes() {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.get("user/two-factor-recovery-codes");
  },
  async regenerateRecoveryCodes() {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.post("user/two-factor-recovery-codes");
  },
  async confirmPassword(payload) {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.post("user/confirm-password", payload);
  },
  logout() {
    return authClient.post("/logout");
  },
  async forgotPassword(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/forgot-password", payload);
  },
  getAuthUser() {
    return authClient.get("/api/user");
  },
  getSessions() {
    return authClient.get("/api/user/sessions");
  },
  purgeSessions(payload) {
    return authClient.post("/api/user/sessions/purge", payload);
  },
  async resetPassword(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/reset-password", payload);
  },
  updatePassword(payload) {
    return authClient.put("/user/password", payload);
  },
  uploadAvatar(formData) {
    return authClient.post("/api/user/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async registerUser(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/register", payload);
  },
  sendVerification(payload) {
    return authClient.post("/email/verification-notification", payload);
  },
  updateUser(payload) {
    return authClient.put("/user/profile-information", payload);
  },
};
