import guest from "@/middleware/guest";

export default [
  {
    path: "/login",
    name: "login",
    meta: { middleware: [guest], layout: "auth" },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/modules/auth/views/Login"),
  },
  {
    path: "/two-factor-challenge",
    name: "twoFactorChallenge",
    meta: { middleware: [guest], layout: "auth" },
    component: () =>
      import(
        /* webpackChunkName: "twoFactorChallenge" */ "@/modules/auth/views/TwoFactorChallenge"
      ),
  },
  {
    path: "/two-factor-challenge-recovery",
    name: "twoFactorChallengeRecovery",
    meta: { middleware: [guest], layout: "auth" },
    component: () =>
      import(
        /* webpackChunkName: "twoFactorChallengeRecovery" */ "@/modules/auth/views/TwoFactorChallengeRecovery"
      ),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    meta: { middleware: [guest], layout: "auth" },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/modules/auth/views/ResetPassword"
      ),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: { middleware: [guest], layout: "auth" },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/modules/auth/views/ForgotPassword"
      ),
  },
];
