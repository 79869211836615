import auth from "@/middleware/auth";

export default [
    {
        path: "/stocktake/create",
        name: "createStocktake",
        meta: {
            middleware: [auth]
        },
        component: () => import(/* webpackChunkName: "createStocktake" */ "@/modules/fuap/stocktake/views/CreateStocktake"),
    },
    {
        path: "/stocktake/:stocktake",
        name: "stocktake",
        meta: {middleware: [auth]},
        component: () =>
            import(
                /* webpackChunkName: "stocktake" */ "@/modules/fuap/stocktake/views/Stocktake"
                ),
    }
];
