<template>
  <div
    class="p-3 flex bg-gray-100 relative dark:bg-gray-800 dark:text-gray-300"
    @click="userMenu = !userMenu"
  >
    <avatar :size="40" :username="user.name" :src="user.avatar"></avatar>

    <div
      class="w-3 h-3 bg-green-400 rounded-full absolute bottom-3 left-10"
    ></div>

    <div class="ml-2 flex flex-col">
      <span>{{ user.name }}</span>
      <span class="text-xs font-extralight">Software Developer</span>
    </div>
    <ul
      v-if="userMenu"
      @click="userMenu = false"
      class="absolute bg-white rounded right-3 top-20 text-sm w-40 pb-3 z-50 dark:bg-gray-900 filter drop-shadow-md"
    >
      <li class="pt-3 px-3">
        <router-link to="/user">User Settings</router-link>
      </li>
      <li class="pt-3 px-3"><button @click="logout">Logout</button></li>
    </ul>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Avatar from "vue-avatar";
export default {
  props: ["user"],
  components: {
    Avatar,
  },
  data() {
    return {
      userMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    ...mapMutations({
      toggleMenu: "ui/SET_MENU",
    }),
  },
};
</script>
