<template>
  <div
    class="flex bg-gray-100 min-h-screen w-screen font-nunito font-normal dark:bg-gray-800"
  >
    <div class="flex flex-col w-screen h-screen">
      <HeaderMenu :user="authUser" :menu="menu" />
      <main class="mb-auto p-6 h-screen overflow-y-auto">
        <slot />
      </main>
      <Footer />
    </div>
  </div>
</template>
<script>
import HeaderMenu from "@/components/core/layouts/HeaderMenu";
import Footer from "@/components/core/layouts/FooterBar";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderMenu,
    Footer,
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("ui", ["menu"]),
    ...mapGetters("ui", ["theme"]),
  },
  beforeMount() {
    this.$store.dispatch("ui/initTheme");
  },
  watch: {
    theme(newTheme) {
      newTheme === "light"
        ? document.querySelector("html").classList.remove("dark")
        : document.querySelector("html").classList.add("dark");
    },
  },
};
</script>
