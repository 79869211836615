import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import MainLayout from "@/layouts/MainLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
//import Header from "@/components/Header";
import dayjs from "dayjs";
import Echo from "laravel-echo";

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
});

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat)

Vue.component("default-layout", MainLayout);
Vue.component("auth-layout", AuthLayout);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
