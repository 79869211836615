import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export default [
  {
    path: "/user",
    name: "user",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/modules/users/views/User"),
  },
  {
    path: "/users",
    name: "users",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "users" */ "@/modules/users/views/Users"),
  },
];
