import auth from "@/middleware/auth";

export default [
  {
    path: "/",
    name: "home",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/modules/fuap/dashboard/views/Dashboard"
      ),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/modules/fuap/dashboard/views/Dashboard"
      ),
  },
];
