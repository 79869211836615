import FuapService from "@/modules/fuap/Service";

export const namespaced = true;

export const state = {
  stocktake: {},
  stocktakes: [],
  fixture: {},
  fixtures: [],
  products: [],
  stores: [],
  bins: [],
};

export const mutations = {
  SET_STOCKTAKE(state, stocktake) {
    state.stocktake = stocktake;
  },
  SET_STOCKTAKES(state, stocktakes) {
    state.stocktakes = stocktakes;
  },
  SET_FIXTURE(state, fixture) {
    state.fixture = fixture;
  },
  SET_FIXTURES(state, fixtures) {
    state.fixtures = fixtures;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  ADD_FIXTURE(state, fixture) {
    fixture.products = fixture.count;
    const obj = state.fixtures.filter(obj => obj.reference == fixture.reference);
    if(obj.length > 0) {
      const fix = obj[0];
      const index = state.fixtures.indexOf(fix);
      state.fixtures.splice(index, 1, fixture);
      return;
    }

    state.fixtures.push(fixture);
  },
  SET_STORES(state, stores) {
    state.stores = stores;
  },
  SET_BINS(state, bins) {
    state.bins = bins;
  }
};

export const actions = {
  async loadStocktake({ commit }, stocktake) {
    try {
      await FuapService.getStocktake(stocktake).then((response) => {
        commit("SET_STOCKTAKE", response.data.data);
      });
    } catch (e) {
      commit("SET_STOCKTAKE", {});
    }
  },
  async loadStocktakes({ commit }, store) {
    try {
      await FuapService.getStocktakes(store).then((response) => {
        commit("SET_STOCKTAKES", response.data.data);
      });
    } catch (e) {
      commit("SET_STOCKTAKES", []);
    }
  },
  async loadFixture({ commit }, fixture) {
    try {
      FuapService.getFixture(fixture).then((response) => {
        commit("SET_FIXTURE", response.data.data);
      });
    } catch (e) {
      commit("SET_FIXTURE", {});
    }
  },
  async loadFixtures({ commit }, stocktake) {
    try {
      await FuapService.getFixtures(stocktake).then((response) => {
        commit("SET_FIXTURES", response.data.data);
      });
    } catch (e) {
      commit("SET_FIXTURES", []);
    }
  },
  async loadProducts({ commit }, fixture) {
    commit("SET_PRODUCTS", []);
    try {
      FuapService.getProducts(fixture).then((response) =>  {
        commit("SET_PRODUCTS", response.data.data);
      });
    } catch (e) {
      commit("SET_PRODUCTS", []);
    }
  },
  async loadStores({ commit }) {
    try {
      await FuapService.getStores().then((response) => {
        commit("SET_STORES", response.data.data);
      });
    } catch (e) {
      commit("SET_STORES", []);
    }
  },
  async loadBins({ commit }, store) {
    try {
      await FuapService.getBins(store).then((response) => {
        commit("SET_BINS", response.data);
      });
    } catch (e) {
      commit("SET_BINS", []);
    }
  },
};

export const getters = {
  stocktake: (state) => {
    return state.stocktake;
  },
  stocktakes: (state) => {
    return state.stocktakes;
  },
  fixture: (state) => {
    return state.fixture;
  },
  fixtures: (state) => {
    return state.fixtures.sort(function(a, b) {
      return a.reference - b.reference;
    });
  },
  products: (state) => {
    return state.products;
  },
  stores: (state) => {
    return state.stores;
  },
  bins: (state) => {
    return state.bins;
  }
};
