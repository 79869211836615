export const namespaced = true;

export const state = {
  menu: true,
  theme: {},
};

export const mutations = {
  SET_MENU(state) {
    state.menu = !state.menu;
  },
  SET_THEME(state, theme) {
    state.theme = theme;
    localStorage.theme = theme;
  },
};

export const actions = {
  initTheme({ commit }) {
    const cachedTheme = localStorage.theme ? localStorage.theme : false;
    const userPrefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    if (cachedTheme) {
      commit("SET_THEME", cachedTheme);
    } else if (userPrefersDark) {
      commit("SET_THEME", "dark");
    } else {
      commit("SET_THEME", "light");
    }
  },
  toggleTheme({ commit }) {
    switch (localStorage.theme) {
      case "light":
        commit("SET_THEME", "dark");
        break;

      default:
        commit("SET_THEME", "light");
        break;
    }
  },
};

export const getters = {
  menu: (state) => {
    return state.menu;
  },
  theme: (state) => {
    return state.theme;
  },
};
