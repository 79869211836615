import { render, staticRenderFns } from "./CogIcon.vue?vue&type=template&id=857c8c28&"
import script from "./CogIcon.vue?vue&type=script&lang=js&"
export * from "./CogIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports