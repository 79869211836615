<template>
  <div class="py-5 pr-5 relative" @click="settingsMenu = !settingsMenu">
    <cog-icon />
    <ul
      v-if="settingsMenu"
      @click="settingsMenu = false"
      class="absolute bg-white rounded right-3 top-20 text-sm w-40 pb-3 z-50 dark:bg-gray-900 filter drop-shadow-md"
    >
      <li class="pt-3 px-3 text-xs">SETTINGS</li>
      <li class="pt-3 px-3 flex justify-between items-center">
        <span>Theme</span> <dark-mode :darkMode="theme"></dark-mode>
      </li>
    </ul>
  </div>
</template>
<script>
import DarkModeSwitch from "@/components/core/utility/DarkModeSwitch";
import CogIcon from "@/components/icons/CogIcon.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    "dark-mode": DarkModeSwitch,
    "cog-icon": CogIcon,
  },
  data() {
    return {
      settingsMenu: false,
    };
  },
  computed: {
    ...mapGetters("ui", ["theme"]),
  },
};
</script>
