<template>
  <header
    class="h-16 w-full bg-white text-gray-500 filter drop-shadow-md flex flex-row justify-between dark:bg-gray-900 dark:text-gray-300 z-30"
  >
    <div class="p-5" @click="toggleMenu"></div>
    <div class="flex relative">
      <settings-menu />

      <user-menu :user="user" />
    </div>
  </header>
</template>
<script>
import { mapMutations } from "vuex";
import Settings from "@/components/core/menus/SettingsMenu";
import User from "@/components/core/menus/UserMenu";

export default {
  props: ["user", "menu"],
  components: {
    "settings-menu": Settings,
    "user-menu": User,
  },
  data() {
    return {
      userMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    ...mapMutations({
      toggleMenu: "ui/SET_MENU",
    }),
  },
};
</script>
