import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import middlewarePipeline from "@/router/middlewarePipeline";
import authRoutes from "@/modules/auth/Routes";
import userRoutes from "@/modules/users/Routes";
import dashRoutes from "@/modules/fuap/dashboard/Routes";
import stocktakeRoutes from "@/modules/fuap/stocktake/Routes";
import fixtureRoutes from "@/modules/fuap/fixture/Routes";

Vue.use(VueRouter);

const baseRoutes = [];

const routes = baseRoutes
  .concat(dashRoutes, stocktakeRoutes, fixtureRoutes, authRoutes, userRoutes)
  .concat([
    {
      path: "/:catchAll(.*)",
      name: "notFound",
      component: () =>
        import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
    },
  ]);

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
