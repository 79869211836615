import Vue from "vue";
import Vuex from "vuex";

import * as auth from "@/modules/auth/Store";
import * as user from "@/modules/users/Store";
import * as ui from "@/store/modules/UI";
import * as fuap from "@/modules/fuap/Store";
import * as stocktake from "@/modules/fuap/stocktake/Store";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    auth,
    user,
    ui,
    fuap,
    stocktake,
  },
});
