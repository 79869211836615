import * as API from "@/services/API";
import { authClient } from "@/modules/auth/Service";

export default {
  getStores() {
    return API.apiClient.get(`/api/store`);
  },
  updateUserStore(payload) {
    return authClient.put("/api/user/store", payload);
  },
  getStocktakes(store) {
    return API.apiClient.get("/api/store/" + store + "/stocktake/all");
  },
  getStocktake(reference) {
    return API.apiClient.get(
      "/api/stocktake/" + reference.replace("CCSTK00", "")
    );
  },
  getFixtures(reference) {
    return API.apiClient.get(
      "/api/stocktake/" + reference.replace("CCSTK00", "") + "/fixture"
    );
  },
  getFixture(reference) {
    return API.apiClient.get("/api/fixture/" + reference);
  },
  getProducts(reference) {
    return API.apiClient.get("/api/fixture/" + reference + "/products");
  },
  getAdjustments(stocktake) {
    return API.apiClient.get("/api/stocktake/" + stocktake + "/adjustments");
  },
  getBins(store) {
    return API.apiClient.get("/api/store/" + store + "/bins");
  },
  createStocktake(payload, store) {
    return API.apiClient.post("/api/store/" + store + "/stocktake", payload);
  },
  getResults(stocktake) {
    return API.apiClient.get("/api/stocktake/" + stocktake + "/results/aggregated");
  },
  updateStocktake(stocktake, payload) {
    return API.apiClient.patch("/api/stocktake/" + stocktake, payload);
  },
  getFixtureResults(stocktake, fixture) {
    return API.apiClient.get("/api/stocktake/" + stocktake + "/results/" + fixture);
  }
};
